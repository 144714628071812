export const messages = {
  captionOff: {
    defaultMessage: 'system-cc-off',
    id: 'fa.feature-gallery.caption.off',
  },
  captionOn: {
    defaultMessage: 'system-cc-on',
    id: 'fa.feature-gallery.caption.on',
  },
  unMute: {
    defaultMessage: 'system-audio-off',
    id: 'fa.feature-gallery.unmute',
  },
  mute: {
    defaultMessage: 'system-audio-on',
    id: 'fa.feature-gallery.mute',
  },
  pause: {
    defaultMessage: 'system-pause',
    id: 'fa.feature-gallery.pause',
  },
  play: {
    defaultMessage: 'system-play',
    id: 'fa.feature-gallery.play',
  },
};
