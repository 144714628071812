import {
  cleanupEmissionConsumptionMarkup,
  renderTextWithFootnotesReferencesV2,
  getConsumptionsAndEmissions,
} from '@oneaudi/feature-app-utils';
import { VueFormatterFeatureServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ReactNode } from 'react';

export interface ConsumptionEmissionValue {
  consumption: ReactNode;
  emission: ReactNode;
}

export interface WltpProps {
  formattedConsumption?: Iterable<ReactNode> | string | undefined;
  formattedEmission?: Iterable<ReactNode> | undefined;
  formattedCo2Class?: Iterable<ReactNode> | undefined;
  formattedDischargedCo2Class?: Iterable<ReactNode> | undefined;
  formattedDischargedConsumption?: Iterable<ReactNode> | undefined;
}

type myCallBack = (wltp: WltpProps[]) => void;
export class VueServiceHelper {
  static getConsumptionAndEmission = async (
    wltpValues: string[],
    mycallback: myCallBack,
    vueFormatterFeatureService: VueFormatterFeatureServiceInterfaceV1,
    localeService: LocaleServiceV1,
  ) => {
    const wltp: WltpProps[] = [];

    const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
      wltpValues,
      vueFormatterFeatureService,
      localeService,
    );
    consumptionsAndEmissionsValues.forEach(
      ({
        formattedConsumption,
        formattedEmission,
        formattedCo2Class,
        formattedDischargedCo2Class,
        formattedDischargedConsumption,
      }) => {
        const wltpData: WltpProps = {};

        if (formattedConsumption && formattedEmission) {
          wltpData.formattedConsumption = renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedConsumption),
          );
          wltpData.formattedEmission = renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedEmission),
          );

          if (formattedCo2Class) {
            wltpData.formattedCo2Class = renderTextWithFootnotesReferencesV2(
              cleanupEmissionConsumptionMarkup(formattedCo2Class),
            );
          }

          if (formattedDischargedCo2Class) {
            wltpData.formattedDischargedCo2Class = renderTextWithFootnotesReferencesV2(
              cleanupEmissionConsumptionMarkup(formattedDischargedCo2Class),
            );
          }

          if (formattedDischargedConsumption) {
            wltpData.formattedDischargedConsumption = renderTextWithFootnotesReferencesV2(
              cleanupEmissionConsumptionMarkup(formattedDischargedConsumption),
            );
          }

          if (Object.keys(wltpData).length > 0) {
            wltp.push(wltpData);
          }
        }
      },
    );
    mycallback(wltp);
  };

  static isAudiCode(value: string): boolean {
    const audiCodeRegExp = /^[A-Z0-9]+$/;
    return audiCodeRegExp.test(value);
  }

  static isCarLineCode(value: string): boolean {
    const carlineRegExp = /^[a-z0-9_-]+$/;
    return carlineRegExp.test(value);
  }
}
