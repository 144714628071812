import { useI18n } from '@oneaudi/i18n-context';
import React from 'react';
import { constants } from '../../../constants';
import { messages } from '../../../i18n/messages';

type ClosedCaptionProps = {
  theme: string;
};

// eslint-disable-next-line
export const ClosedCaptionOn: React.FC<ClosedCaptionProps> = (props) => {
  const { theme } = props;
  const currentColor =
    theme === constants.theme.lightTheme ? constants.color.white : constants.color.white;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9794 4.5H1V17.25C1 18.2381 1.76193 19 2.75 19H4.45794L4.92419 18.5H2.75C2.03807 18.5 1.5 17.9619 1.5 17.25V5H17.5131L17.9794 4.5ZM7.65885 18.5H22.5V6.25C22.5 5.53807 21.9619 5 21.25 5H20.2478L20.7141 4.5H21.25C22.2381 4.5 23 5.26193 23 6.25V19H7.19259L7.65885 18.5ZM12.956 12.8195L13.9356 11.769C14.0041 13.0829 14.8775 13.7858 16.1983 13.7858C17.2508 13.7858 17.9911 13.5313 18.5 13.3V14.26C17.9333 14.5029 17.274 14.7111 16.0826 14.7111C14.4865 14.7111 13.3879 13.9917 12.956 12.8195ZM16.1189 9.42775L16.9532 8.53301C17.5198 8.5888 18.0481 8.71135 18.4075 8.87012V9.80699C17.887 9.5988 17.112 9.42531 16.2677 9.42531C16.2172 9.42531 16.1676 9.42613 16.1189 9.42775ZM9.3311 13.7742C9.20787 13.7817 9.07981 13.7858 8.94676 13.7858C7.57037 13.7858 6.67976 13.0224 6.67976 11.5998C6.67976 10.3043 7.36218 9.42531 9.01616 9.42531C9.8605 9.42531 10.6354 9.5988 11.1559 9.80699V8.87012C10.6586 8.65036 9.83737 8.5 9.03929 8.5C6.64507 8.5 5.5 9.83013 5.5 11.5998C5.5 13.3629 6.59578 14.5628 8.46934 14.6983L9.3311 13.7742Z"
        fill={currentColor}
      />
      <title>{useI18n(messages.captionOn)}</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4888 1.62489L3.51902 21.9675L3.06192 21.5412L22.0317 1.19864L22.4888 1.62489Z"
        fill={currentColor}
      />
    </svg>
  );
};

export default ClosedCaptionOn;
