/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Text } from '@audi/audi-ui-react';
import { ClosedCaptionButton, PlayButton, MuteButton, Video, VideoContainer } from './style';
import { PlayIcon } from '../icons/PlayIcon';
import { PauseIcon } from '../icons/PauseIcon';
import { MuteIcon } from '../icons/MuteIcon';
import { UnMuteIcon } from '../icons/UnMuteIcon';
import { ClosedCaptionOn } from '../icons/ClosedCaptionOn';
import { ClosedCaptionOff } from '../icons/ClosedCaptionOff';
import { parseAssetValue } from '../../../helper/parseAssetValues';

export interface TextTrackCue {
  line: any;
}
export interface VideoProps {
  alternateDescription: string;
  autoPlay: boolean;
  loop: boolean;
  closedCaption: string;
  theme: string;
  videoHasAudio: boolean;
  videoUrlLargeScreen: string;
  videoUrlSmallScreen: string;
  index: number;
  currentVideo: HTMLVideoElement | null;
  isCurrent: number;
  // eslint-disable-next-line
  handleClick: Function;
}

export interface VTT {
  value: TextTrackCue;
  index: number;
  array: TextTrackCue[];
  line: number;
}

export const FeatureGalleryVideo: React.FC<VideoProps> = (props) => {
  const [playing, toggleVideo] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMute, setMuteVideo] = React.useState(true);
  const [showClosedCaption, setClosedCaption] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const {
    autoPlay = false,
    videoHasAudio,
    alternateDescription,
    closedCaption,
    loop,
    theme,
    videoUrlLargeScreen,
    videoUrlSmallScreen,
    index,
    handleClick,
    currentVideo: video,
    isCurrent,
  } = props;

  function useOnScreen(options: any) {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);

      if (!visible) {
        pauseVideo();
      } else {
        playVideo();
      }
    }, options);

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }

  React.useEffect(() => {
    useOnScreen({
      root: null,
      rootElement: null,
      threshold: [0.01],
    });

    if (visible && autoPlay && video && video.autoplay && index === isCurrent) {
      toggleVideo(true);
      setMuteVideo(true);
      setClosedCaption(false);
      video.muted = true;
      video.textTracks[0].mode = 'hidden';
      desktopViewPortStyle();
      smallViewPortStyle();
    }
    if (visible && !autoPlay && video && !video.autoplay) {
      toggleVideo(false);
      setMuteVideo(true);
      video.muted = true;
      setClosedCaption(false);
      video.textTracks[0].mode = 'hidden';
      desktopViewPortStyle();
      smallViewPortStyle();
    }
  }, [visible, video, isCurrent]);

  const pauseVideo = () => {
    if (video !== null) {
      video.pause();
    }

    setIsPlaying(false);
  };

  const smallViewPortStyle = () => {
    if (video) {
      if (typeof window !== 'undefined' && window.innerWidth <= 1024) {
        video.style.height = 'auto';
      } else {
        video.style.objectFit = 'contain';
      }
    }
  };

  const desktopViewPortStyle = () => {
    if (video) {
      if (typeof window !== 'undefined' && window.innerWidth >= 1024) {
        video.style.height = '100%';
      } else {
        video.style.objectFit = 'contain';
      }
    }
  };

  const playVideo = () => {
    if (!isPlaying && autoPlay && video && video.autoplay && index === isCurrent) {
      video.currentTime = 0;

      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_: any) => {})
          .catch((error: any) => {
            return error;
          });
      }
    } else {
      video?.load();
    }

    setIsPlaying(true);
  };

  const muteVideo = (event: any): void => {
    event.preventDefault();
    if (video) {
      if (isMute) {
        video.muted = false;

        setMuteVideo(false);
      } else {
        video.muted = true;

        setMuteVideo(true);
      }
    }
  };

  const togglePlay = (event: any): void => {
    event.preventDefault();

    if (video?.paused) {
      const promise = video.play();
      if (promise !== null) {
        promise.catch(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          video.play();
        });
      }

      toggleVideo(true);
    } else {
      video?.pause();

      toggleVideo(false);
    }
  };
  const toggleClosedCaption = () => {
    if (video) {
      if (!showClosedCaption) {
        video.textTracks[0].mode = 'showing';

        setClosedCaption(true);

        const cues = video.textTracks[0].cues!;
        const values = Object.values(cues) as VTTCue[];
        values.forEach((vtt: VTTCue) => {
          vtt.line = 0;
        });

        smallViewPortStyle();
      } else {
        video.textTracks[0].mode = 'hidden';

        setClosedCaption(false);

        desktopViewPortStyle();
      }
    }
  };

  return (
    <VideoContainer data-testid="carouselItemVideoContainer" id={`videoIdContainer${index}`}>
      <PlayButton
        onClick={(e) => {
          const id = e.currentTarget.textContent;
          togglePlay(e);
          handleClick(id);
        }}
        data-testid="carouselItemPlayButton"
        aria-label="Play/Pause"
      >
        {playing ? <PauseIcon theme={theme} /> : <PlayIcon theme={theme} />}
      </PlayButton>
      {videoHasAudio ? (
        <MuteButton
          onClick={(e) => {
            const id = e.currentTarget.textContent;
            muteVideo(e);
            handleClick(id);
          }}
          data-testid="muteButton"
          aria-label="Mute/UnMute"
        >
          {isMute ? <MuteIcon theme={theme} /> : <UnMuteIcon theme={theme} />}
        </MuteButton>
      ) : null}
      {videoHasAudio && closedCaption !== '' ? (
        <ClosedCaptionButton
          onClick={(e) => {
            const id = e.currentTarget.textContent;
            toggleClosedCaption();
            handleClick(id);
          }}
          data-testid="closedCaptionButton"
          aria-label="Closed Caption"
        >
          {showClosedCaption ? (
            <ClosedCaptionOff theme={theme} />
          ) : (
            <ClosedCaptionOn theme={theme} />
          )}
        </ClosedCaptionButton>
      ) : null}
      <Video
        id={`videoId${index}`}
        data-testid="carouselItemVideo"
        crossOrigin="anonymous"
        preload="none"
        title={alternateDescription}
        playsInline
        muted
        onEnded={() => toggleVideo(false)}
        loop={loop}
      >
        {typeof window !== 'undefined' && visible ? (
          window.innerWidth < 768 ? (
            <source src={parseAssetValue(videoUrlSmallScreen)?.src} />
          ) : (
            <source src={parseAssetValue(videoUrlLargeScreen)?.src} />
          )
        ) : null}

        <track src={closedCaption} kind="captions" />

        <Text data-testid="carouselItemVideoAlt">{alternateDescription}</Text>
      </Video>
    </VideoContainer>
  );
};
