import React from 'react';
import { constants } from '../../../constants';

type RightArrowProps = {
  theme: string;
};
// eslint-disable-next-line
export const RightArrow: React.FC<RightArrowProps> = (props) => {
  const { theme } = props;
  const currentColor =
    theme === constants.theme.darkTheme ? constants.color.white : constants.color.black;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>forward-small</title>
      <path
        d="M10 16.9L15.5 11.5 10 6.1"
        stroke={currentColor}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};
