import {
  DataLayerEventAttributes,
  makeEmptyEventAttributes,
} from '@volkswagen-onehub/oneaudi-os-onesight';

export function addMissingTfsEventAttributes(attributes: DataLayerEventAttributes) {
  return {
    ...makeEmptyEventAttributes(),
    ...attributes,
  };
}
