export const constants = {
  button: {
    primary: 'primary',
  },
  color: {
    activeSlideColor: '#BB0A30',
    black: '#000000',
    darkGrey: '#333333',
    grey: '	#999999',
    lightGrey: '#F2F2F2',
    white: '#FFFFFF',
  },
  ctaVariant: {
    text: 'text',
  },
  direction: {
    left: 'left',
    right: 'right',
  },
  displaySizes: {
    mobile: '375px',
    tablet: '768px',
    smallDesktop: '1024px',
    desktop: '1440px',
    largeDesktop: '1920px',
  },
  flexDirection: {
    column: 'column',
    row: 'row',
  },
  fontVariant: {
    display1: 'display1',
    display2: 'display2',
    order1: 'order1',
    order2: 'order2',
    order3: 'order3',
    order4: 'order4',
    copy1: 'copy1',
    copy2: 'copy2',
    copy3: 'copy3',
    copy4: 'copy4',
  },
  fontWeight: {
    bold: 'bold',
    normal: 'normal',
  },
  justification: {
    center: 'center',
    start: 'start',
  },
  assetType: {
    image: 'image',
    video: 'video',
  },
  middle: '50%',
  position: {
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    top: 'top',
  },
  sizes: {
    xxs: 'xxs',
    xs: 'xs',
    s: 's',
    m: 'm',
    l: 'l',
    xl: 'xl',
    xxl: 'xxl',
    xxxl: 'xxxl',
  },
  textBlockPosition: {
    bottomLeft: 'bottomLeft',
    bottomRight: 'bottomRight',
    middleLeft: 'middleLeft',
    middleRight: 'middleRight',
    topLeft: 'topLeft',
    topRight: 'topRight',
  },
  theme: {
    darkTheme: 'darkTheme',
    lightTheme: 'lightTheme',
  },

  wltp: {
    audiCode: 'audiCode',
    carlineCode: 'carlineCode',
    wltpText: 'wltpText',
  },
};
