// eslint-disable-next-line
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
// eslint-disable-next-line
import React from 'react';
import { audiLightTheme, audiDarkTheme, Button, Text, ThemeProvider } from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { TextBlock, TitleTextBlock, CTAContainer } from './style';
import { constants } from '../../../constants';

interface TextBlockProps {
  ctaLink: string;
  ctaText: string;
  title: string;
  bodyText: string;
  theme: string;
  openIn: string;
  width?: number | undefined;
  ariaLabel?: string;
  // eslint-disable-next-line
  trackExitLink: Function;
  // eslint-disable-next-line
  handleClick: Function;
  slideIndex: number;
}
// eslint-disable-next-line
export const FeatureGalleryTextBlock: React.FC<TextBlockProps> = (props) => {
  const { bodyText, ctaLink, ctaText, title, theme, openIn, ariaLabel, width, ...ueProps } = props;

  const themeProvider = () => {
    const { darkTheme } = constants.theme;
    if (theme === darkTheme) {
      return audiDarkTheme;
    }
    return audiLightTheme;
  };

  function setCtaUrl(link: string) {
    if (!(link.indexOf('/') === 0)) {
      return link;
    }
    const url = typeof window !== 'undefined' && window.location.origin;
    return typeof window !== 'undefined' ? url + link : undefined;
  }

  return (
    <ThemeProvider theme={themeProvider()} {...ueProps}>
      <TextBlock theme={theme} className="fg-text-block-content">
        <TitleTextBlock width={width}>
          <Text as="strong" variant="copy1" weight="bold" {...ueProps}>
            <UeElement type="text" property="title" label="Title">
              {renderTextWithFootnotesReferences(title)}
            </UeElement>
          </Text>
          <Text as="p" variant="copy1" {...ueProps}>
            <UeElement type="text" property="bodyText" label="Body text">
              {renderTextWithFootnotesReferences(bodyText)}
            </UeElement>
          </Text>
        </TitleTextBlock>
        {ctaText ? (
          <CTAContainer>
            <Button
              variant="text"
              stretch
              newWindow={openIn !== 'Same tab'}
              href={setCtaUrl(ctaLink)}
              aria-label={ariaLabel}
              onClick={() => {
                // eslint-disable-next-line
                props.trackExitLink(ctaText, ctaLink);
              }}
            >
              <UeElement type="text" property="cta1_ctaText1" label="CTA text">
                {ctaText}
              </UeElement>
            </Button>
          </CTAContainer>
        ) : null}
      </TextBlock>
    </ThemeProvider>
  );
};
