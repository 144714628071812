import styled from 'styled-components';
import { constants } from '../../../constants';

interface TextBlockProps {
  theme: boolean;
  width?: number;
}

const device = {
  desktop: `(min-width: ${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width: ${constants.displaySizes.largeDesktop})`,
  mobile: `(min-width: ${constants.displaySizes.mobile})`,
  smallDesktop: `(min-width: ${constants.displaySizes.smallDesktop})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

export const TextBlock = styled.div<TextBlockProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  padding-bottom: 24px;
  position: relative;

  @media ${device.tablet} {
    margin-top: 0px;
    min-height: 1px;
    height: auto;
    padding: 16px 0px 36px;
  }

  @media ${device.smallDesktop} {
    height: auto;
    padding: 16px 0px 24px;
  }

  @media ${device.desktop} {
    height: auto;
  }

  @media ${device.largeDesktop} {
    height: auto;
  }
`;

export const TitleTextBlock = styled.div<TextBlockProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px 0 24px;

  sup {
    font-feature-settings: normal !important;
  }

  @media ${device.tablet} {
    margin: 0 0 16px;
    max-width: ${(props) => `calc(100% - ${props.width}px - 17px)`};
  }

  @media ${device.smallDesktop} {
    margin: 0 0 24px;
    max-width: ${(props) => `calc(100% - ${props.width}px - 48px)`};
  }

  @media ${device.desktop} {
    max-width: ${(props) => `calc(100% - ${props.width}px - 97px)`};
  }

  @media ${device.largeDesktop} {
    max-width: ${(props) => `calc(100% - ${props.width}px - 60px)`};
  }
`;

export const CTAContainer = styled.div`
  @media ${device.mobile} {
    button {
      margin: 0;
    }
  }

  @media ${device.tablet} {
    width: 344px;
  }

  @media ${device.desktop} {
    width: 270px;
  }
  @media ${device.smallDesktop} {
    width: 270px;
  }
  @media ${device.smallDesktop} {
    width: 270px;
  }

  @media ${device.largeDesktop} {
    width: 270px;
  }

  button {
    margin: 0;
  }
`;
