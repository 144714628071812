/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactNode } from 'react';
import { ContentService, ContentChangeListener } from '@oneaudi/content-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
// eslint-disable-next-line
import { VueFormatterFeatureServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ContentContext } from './ContentContext';
import normalizeContent from './ContentMapping';

export const ContentContextProvider: React.FC<{
  featureAppId: string;
  children?: ReactNode;
  contentService: ContentService;
  footnoteReferenceService: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  vueFormatterService: VueFormatterFeatureServiceInterfaceV1;
  localeService: LocaleServiceV1;
  // eslint-disable-next-line
  loggerService: any;
}> =
  // eslint-disable-next-line
  ({
    featureAppId,
    contentService,
    children,
    vueFormatterService,
    footnoteReferenceService,
    localeService,
    loggerService,
  }) => {
    const [rawContent, setRawContent] = useState<any>(contentService?.getContent());
    const content = normalizeContent(rawContent);
    contentService?.onContentChange(setRawContent as ContentChangeListener);

    // eslint-disable-next-line
    return (
      <ContentContext.Provider
        // eslint-disable-next-line
        value={{
          featureAppId,
          content,
          footnoteReferenceService,
          vueFormatterService,
          localeService,
          loggerService,
        }}
      >
        {children}
      </ContentContext.Provider>
    );
  };

ContentContextProvider.displayName = 'ContentContextProvider';
