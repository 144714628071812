/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function parseAssetValue(assetValue?: string): any {
  const asset = assetValue;
  //   const assetUrl = assetValue ? assetValue?.trim() : '';
  let parsedAssetUrl: any;

  if (!asset?.startsWith('{')) {
    // standard URL, no parsing
    return { src: assetValue };
  }

  try {
    parsedAssetUrl = JSON.parse(asset) as any;
  } catch (error) {
    // parsing failed
    throw new Error(`Error parsing JSON assetUrl (${assetValue})`);
  }

  if (parsedAssetUrl) {
    // parse video url
    const { sourceFormats, imageFormats } = parsedAssetUrl.video[0];
    const parsedAssetValue: any = { src: sourceFormats.content_720p };
    if (!parsedAssetValue.src) {
      throw new Error('content_720p not found in sourceFormats');
    }
    if (imageFormats) {
      parsedAssetValue.posterSrc = imageFormats['web-largest'] || imageFormats.thumb_1080p;
    }
    return parsedAssetValue;
  }

  return { src: '' };
}
