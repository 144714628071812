import { createContext } from 'react';
// eslint-disable-next-line
import { VueFormatterFeatureServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';

export type AppServices = {
  featureAppId: string;
  // eslint-disable-next-line
  content?: any;
  // eslint-disable-next-line
  trackingService?: any;
  // eslint-disable-next-line
  loggerService?: any;
  localeService: GfaLocaleServiceV1;
  vueFormatterService: VueFormatterFeatureServiceInterfaceV1;
  footnoteReferenceService: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
};

export const ContentContext = createContext<AppServices | null>({
  featureAppId: '',
  loggerService: console,
  localeService: {} as GfaLocaleServiceV1,
  vueFormatterService: {} as VueFormatterFeatureServiceInterfaceV1,
  footnoteReferenceService: {} as AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
});
