import styled from 'styled-components';
import { constants } from '../../../constants';

interface WLTPContainerProps {
  theme: string;
}

const device = {
  desktop: `(min-width: ${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width: ${constants.displaySizes.largeDesktop})`,
  mobile: `(min-width: ${constants.displaySizes.mobile})`,
  smallDesktop: `(min-width: ${constants.displaySizes.smallDesktop})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

export const WLTPContainer = styled.div<WLTPContainerProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  padding-bottom: 24px;

  .fg-wltp-items {
    margin: 0 5px 0 0;
  }

  sup a {
    font-feature-settings: normal !important;
    font-weight: 400 !important;
  }

  @media ${device.mobile} {
    p {
      color: ${(props) =>
        props.theme === constants.theme.darkTheme ? constants.color.white : constants.color.black};
    }
  }
`;

export const MediaDisclaimerContainer = styled.div`
  sup a {
    font-feature-settings: normal !important;
    font-weight: 400 !important;
  }
`;
